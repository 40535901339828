import { FC, useEffect } from "react"

import config from "../../config.json"

import { SentryErrorBoundary } from "../shared-components/Shared/ErrorBoundary"
import { Base } from "../shared-components/Base/Base"

import { getCookie, removeAuthCookie } from "../utils/auth0"

const UnsetToken: FC = () => {
  useEffect(() => {
    if (
      window !== undefined &&
      document !== undefined &&
      localStorage !== undefined
    ) {
      localStorage.removeItem("habito/token")

      const redirectURI = getCookie("authFlows_logoutContinuation") || ""

      removeAuthCookie("authFlows_logoutContinuation")

      window.location.replace(redirectURI)
    }
  })

  /**
   * We necessarily don't want to use WrappedBase here.
   * WrappedBase deals with either an already authenticated user or a user who needs a token.
   * Either way that isn't relevant for the unset-token page, because we're actually in the process
   * of wiping the token and then immediately redirecting.
   **/
  return (
    <SentryErrorBoundary>
      <Base
        metaTitle="Unset token"
        metaDescription=""
        canonicalUrl="https://www.habito.com/unset-token"
        noIndex={true}
        pageName="unset token"
        intercom={false}
        config={config}
      />
    </SentryErrorBoundary>
  )
}

export default UnsetToken
